import isArray from 'lodash/isArray'
import flatten from 'lodash/flatten'
import every from 'lodash/every'
import map from 'lodash/map'
import forEach from 'lodash/forEach'
import { EDIT_OVERLAY_Z_INDEX, POLYGON_STYLES } from './constants'
import * as AMapTypes from './types'
import OverlayEditor, { Props as OverlayEditorProps } from './overlayEditor'

interface Props extends OverlayEditorProps {
  overlayOptions?: AMap.PolygonOptions;
  /** polygonEditor 设置参数 https://lbs.amap.com/api/jsapi-v2/documentation#polygoneditor */
  editorOptions?: any;
}

class PolygonEditor extends OverlayEditor {
  static DEFAULT_OPTS = {
    ...POLYGON_STYLES,
    zIndex: EDIT_OVERLAY_Z_INDEX
  }

  opts: AMap.PolygonOptions

  constructor (props: Props) {
    const { overlayOptions = {}, quMap, events } = props
    super({ quMap, events })

    this.editor = new this.AMap.PolygonEditor(this.amap)
    this.mouseTool.on('draw', (e: AMapTypes.DrawEvent) => {
      const pointsCount = e.obj.getPath().length
      if (pointsCount > 2) {
        this.mouseTool.close()
        this.preparePolygon(e.obj)
        this.events.onFinish(this.getOverlaysGeoJSON())
      } else {
        // 删除错误的 Polygon，让用户重新绘制
        this.amap.remove(e.obj)
      }
    })
    this.opts = {
      ...PolygonEditor.DEFAULT_OPTS,
      fillOpacity: 0.1,
      strokeWeight: 2,
      zIndex: EDIT_OVERLAY_Z_INDEX,
      ...overlayOptions
    }
  }

  /**
   * @description
   * PolygonEditor 不支持直接读取嵌套 paths
   * 传入一个 paths 嵌套数组，遍历每一个 Polygon Path 并创建Polygon
   */
  // FIXME: 支持完整的 path 类型 https://lbs.amap.com/api/jsapi-v2/documentation#polygon
  load (path: any): void {
    this.close()
    let paths = [path]

    if (every(flatten(path), p => isArray(p))) {
      paths = map(path, (p: Array<any>) => p[0])
    }

    forEach(paths, (p) => {
      const overlay = new this.AMap.Polygon({
        path: p,
        ...this.opts
      })
      this.preparePolygon(overlay)
      this.amap.add(overlay)
    })
    this.isEditing = true
  }

  open (): void {
    this.isEditing = true
    this.amap.setDefaultCursor('crosshair')
    this.mouseTool.polygon({
      draggable: false,
      ...this.opts
    })

    this.disableDoubleClick()
    this.events.onOpen()
  }

  /**
   * @description
   * 鼠标点击时开启编辑
   * 右键单击时会关闭编辑
   */
  preparePolygon (polygon: AMap.Polygon): void {
    polygon.setOptions({ cursor: 'pointer' })
    this.editor.setTarget(polygon)
    polygon && this.overlays.push(polygon)
    polygon.on('click', () => {
      if (this.isEditing) {
        this.editor.setTarget(polygon)
        this.editor.open()
        // 临时关闭 mouseTool 防止绘制新点
        this.mouseTool.close()
        setTimeout(() => {
          this.mouseTool.polygon({
            draggable: false,
            ...this.opts
          })
        }, 0)
      }
    })
    polygon.on('mousedown', () => {
      this.editor.close()
    })
  }
}

export default PolygonEditor

// TODO:
// 1. 验证 load 参数中 path 是否符合格式
// 2. add type
