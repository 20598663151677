/* eslint-disable */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react'
import { QUMap, QUMapTypes } from '@quanturban/map-core'

interface Props {
  token: string;
  version?: string;
  options?: Partial<QUMapTypes.IMapConfig>;
  plugins?: string[];
  style?: React.CSSProperties;
  className?: string;
  onMapInitialized?: (instance: any, map: any) => void;
}

/**
 * @description 地图组件
 * @param token         地图API Key
 * @param version       地图API版本, 默认为2.0
 * @param options       地图选项: style, zoom, center, pitch, rotation
 * @param plugins       地图支持的plugins: AMap.MouseTool, AMap.CircleEditor, AMap.RectangleEditor, AMap.PolyEditor,
 *   AMap.GeoJSON, AMap.PlaceSearch
 * @param style         为地图容器添加样式
 * @param className     为地图容器添加className
 * @param children      地图容器内的子组件: layer, controller, poi等
 * @param onError       地图发生错误时调用该事件
 * @param onMapInitialized       地图初始化完成时调用该事件
 */
const QUMapMemo: React.FC<Props> = (props: Props) => {
  const {
    token,
    version = '2.0',
    options,
    plugins,
    style,
    className,
    onError,
    onMapInitialized
  } = props
  const container = useRef<HTMLDivElement>(null)
  const [amap, setAmap] = useState<QUMap>()
  const [quMap, setQUMap] = useState<QUMap>()
  useEffect(() => {
    const fetchMap = async () => {
      const instance = await new QUMap({
        id: container.current,
        version,
        key: token,
        plugins: plugins || [],
        options,
        ...props
      })

      setQUMap(instance)
      const map = await instance.getMap()
      setAmap(map)
      onMapInitialized && onMapInitialized(instance, map)
    }

    token && fetchMap()

    return () => {
      if (quMap) {
        quMap.destroy()
      }
    }
  }, [options, version, token, plugins])

  useEffect(() => {
    if (amap && options.style) {
      amap.setMapStyle(options.style)
    }
  }, [JSON.stringify(options.style)])

  useEffect(() => {
    if (amap && options.zoom) {
      amap.setZoom(options.zoom)
    }
  }, [options.zoom])

  useEffect(() => {
    if (amap && options.center) {
      amap.setCenter(options.center)
    }
  }, [JSON.stringify(options.center)])
  useEffect(() => {
    if (amap && options.pitch) {
      amap.setPitch(options.pitch)
    }
  }, [options.pitch])
  useEffect(() => {
    if (amap && options.rotation) {
      amap.setRotation(options.rotation)
    }
  }, [options.rotation])

  if (token) {
    return (
      <div ref={container} style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        minHeight: 'inherit'
      }} />
    )
  } else {
    onError && onError('请传入API Key!')
    return <div>请传入高德地图API Key!</div>
  }
}

export const MemoQUMap = React.memo<any>(QUMapMemo, () => {
  return true
})

export default MemoQUMap
