import React, { useState, ChangeEvent, useEffect } from 'react'
import map from 'lodash/map'
import cx from 'clsx'

type Option = {
  label: string,
  value: string
}

export interface Props {
  name: string;
  options: Option[];
  onChange?: (value: Option['value']) => void;
  defaultValue?: Option['value'];
  value?: Option['value']
}

/**
 * @description 单选组件
 * @internal
 * @category Component
 */
const RadioGroup: React.FC<Props> = ({
  name,
  onChange,
  defaultValue,
  value,
  options = []
}) => {
  const [current, setCurrent] = useState(value ?? defaultValue)
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrent(e.target.value)
    onChange && onChange(e.target.value)
  }

  useEffect(() => {
    if (value) {
      setCurrent(value)
    }
  }, [value])

  return (
    <div className="qu-radiogroup">
      {
        map(options, o => (
          <label key={o.value} className={cx('qu-radio', { checked: current === o.value })}>
            <input name={name} type="radio" value={o.value} checked={current === o.value} onChange={handleChange} />
            <span className="qu-radio__label">{o.label}</span>
          </label>
        ))
      }
    </div>
  )
}

export default RadioGroup

// TODO:
// 2. style
