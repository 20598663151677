import {
  lineString,
  polygon,
  multiPoint,
  multiLineString,
  multiPolygon
} from '@turf/helpers'
import centroid from '@turf/centroid'
import type { Position, 
  Geometry, 
  LineString, 
  Polygon, 
  MultiPoint,
  MultiPolygon, 
  MultiLineString } from '@turf/helpers/dist/js/lib/geojson'

export type OverlayType = 'Marker' | 'CircleMarker' | 'Polyline'  | 'Polygon' 
| 'Points' | 'Polygons' | 'Polylines' | string

export function getCenter (coordinates: Geometry['coordinates'], overlayType: OverlayType): Position {
  if (overlayType === 'Marker' || overlayType === 'CircleMarker') {
    return coordinates as Position
  }

  if (overlayType === 'Polyline') {
    if (coordinates.length > 2) {
      return coordinates[Math.floor(coordinates.length / 2)] as Position
    } else {
      return centroid(lineString(coordinates as LineString['coordinates'])).geometry.coordinates
    }
  }

  if (overlayType === 'Polygon') {
    return centroid(polygon(coordinates as Polygon['coordinates'])).geometry.coordinates
  }
  
  if (overlayType === 'Points') {
    return centroid(multiPoint(coordinates as MultiPoint['coordinates'])).geometry.coordinates
  }

  if (overlayType === 'Polylines') {
    return centroid(multiLineString(coordinates as MultiLineString['coordinates'])).geometry.coordinates
  }

  if (overlayType === 'Polygons') {
    return centroid(multiPolygon(coordinates as MultiPolygon['coordinates'])).geometry.coordinates
  }

  // eslint-disable-next-line no-console
  console.info('Not supported overlayType', overlayType)

  return []
}
