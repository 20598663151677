import map from 'lodash/map'
import includes from 'lodash/includes'
import omit from 'lodash/omit'
import { getFillOpts, getOutlineOpts, getRadiusOpts, getZIndex } from './utils/overlayFns'
import { getCenter } from './utils/geospatial'
import {
  CIRCLE_MARKER_RADIUS,
  FILL_COLOR,
  LABEL_MARKER_STYLES,
  MARKER_OPTS,
  POLYGON_STYLES,
  STROKE_OPACITY,
  TEXT_OPTS
} from './constants'
import { LayerType, POITextType, POIType, QUMapProps } from './types'
import { clearEvents } from './events'

class OverlayRender {
  AMap: any
  amap: AMap.Map

  constructor (props: QUMapProps) {
    this.AMap = props.AMap
    this.amap = props.amap
  }

  createIconMarker (poi: POIType, layer: LayerType): AMap.Marker {
    const iconSize = layer.iconConfig.size
    const iconOffset = layer.iconConfig.offset
    return new this.AMap.Marker({
      zIndex: getZIndex(poi, layer),
      icon: new this.AMap.Icon({
        image: layer.iconConfig.image,
        size: new this.AMap.Size(iconSize[0], iconSize[1]),
        imageSize: new this.AMap.Size(iconSize[0], iconSize[1])
      }),
      offset: new this.AMap.Pixel(iconOffset[0], iconOffset[1]),
      ...poi.amapOpts,
      position: poi[layer.field],
      extData: {
        ...poi.extData,
        qu_type: 'IconMarker'
      }
    })
  }

  createCircleMarker (poi: POIType, layer: LayerType): AMap.CircleMarker {
    return new this.AMap.CircleMarker({
      zIndex: getZIndex(poi, layer),
      center: poi[layer.field],
      radius: layer.radiusConfig?.radius || CIRCLE_MARKER_RADIUS,
      strokeOpacity: STROKE_OPACITY,
      fillColor: FILL_COLOR,
      ...poi.amapOpts,
      ...getFillOpts(poi, layer),
      ...omit(getOutlineOpts(poi, layer), ['strokeStyle', 'strokeDasharray']),
      ...getRadiusOpts(poi, layer),
      extData: {
        ...poi.extData,
        qu_type: 'CircleMarker'
      }
    })
  }

  createLabelMarker (poi: POIType, layer: LayerType): AMap.LabelMarker {
    let customLabelOpts: POITextType = {}
    const label = layer.label
    const position = getCenter(poi[layer.field], poi.overlayType as string)
    let radius = layer.radiusConfig?.radius ?? 0
    if (poi.overlayType === 'CircleMarker' || poi.overlayType === 'Circle') {
      radius = getRadiusOpts(poi, layer).radius ?? radius
    } else {
      radius = 0
    }
    let offset = label.offset ?? [0, 0]
    switch (label.direction) {
      case 'top':
        offset = [0, -radius]
        break
      case 'right':
        offset = [radius, -label.fontSize * 0.21]
        break
      case 'bottom':
        offset = [0, radius]
        break
      case 'left':
        offset = [-radius, -label.fontSize * 0.21]
        break
    }

    if (label) {
      customLabelOpts = {
        content: poi[label.field] + '',
        direction: label.direction,
        offset,
        style: {
          fontSize: label.fontSize,
          fillColor: label.fillColor,
          backgroundColor: label.backgroundColor
        }
      }
    }

    return new this.AMap.LabelMarker({
      zIndex: getZIndex(poi, layer),
      position,
      text: {
        ...LABEL_MARKER_STYLES,
        // 这里强制吧label转为string, 如果是数字, 会报错.
        content: poi.label + '',
        ...customLabelOpts
      },
      ...poi.amapOpts,
      extData: {
        ...poi.extData,
        qu_type: 'LabelMarker'
      }
    })
  }

  createPolyline (poi: POIType, layer: LayerType): AMap.Polyline {
    return new this.AMap.Polyline({
      zIndex: getZIndex(poi, layer),
      ...getOutlineOpts(poi, layer),
      ...poi.amapOpts,
      path: poi[layer.field],
      extData: {
        ...poi.extData,
        qu_type: 'Polyline'
      }
    })
  }

  createPolygon (poi: POIType, layer: LayerType): AMap.Polygon {
    return new this.AMap.Polygon({
      zIndex: getZIndex(poi, layer),
      ...POLYGON_STYLES,
      ...getFillOpts(poi, layer),
      ...getOutlineOpts(poi, layer),
      ...poi.amapOpts,
      path: poi[layer.field],
      extData: {
        ...poi.extData,
        qu_type: 'Polygon'
      }
    })
  }

  createCircle (poi: POIType, layer: LayerType): AMap.Circle {
    // TODO: To be continue
    return new this.AMap.Circle({
      zIndex: getZIndex(poi, layer),
      ...poi.amapOpts,
      center: poi[layer.field],
      extData: {
        ...poi.extData,
        qu_type: 'Circle'
      }
    })
  }

  createText (poi: POIType, layer: LayerType): AMap.Text {
    return new this.AMap.Text({
      zIndex: getZIndex(poi, layer),
      ...TEXT_OPTS,
      anchor: 'top-center',
      ...poi.amapOpts,
      text: poi.text,
      position: poi[layer.field],
      extData: {
        ...poi.extData,
        qu_type: 'Text'
      }
    })
  }

  createAggMarker (poi: POIType, layer: LayerType): AMap.Marker {
    return new this.AMap.Marker({
      zIndex: getZIndex(poi, layer),
      ...this.transformOverlayOpts(MARKER_OPTS),
      ...poi.amapOpts,
      position: poi[layer.field],
      content: poi.content,
      label: poi.label,
      extData: {
        ...poi.extData,
        qu_type: 'AggMarker'
      }
    })
  }

  createMarker (poi: POIType, layer: LayerType): AMap.Marker {
    return new this.AMap.Marker({
      zIndex: getZIndex(poi, layer),
      ...this.transformOverlayOpts(MARKER_OPTS),
      content: poi.content,
      label: {
        content: poi.label,
        direction: 'top'
      },
      ...poi.amapOpts,
      position: poi[layer.field],
      extData: {
        ...poi.extData,
        qu_type: 'Marker'
      }
    })
  }

  removeOverlaysByIds (ids: string[]): void {
    map(this.amap.getAllOverlays(), overlay => {
      if (includes(ids, overlay.getExtData().qu_formattedId)) {
        clearEvents(overlay)
        overlay.remove()
      }
    })
  }

  transformOverlayOpts (opts: Record<string, unknown>): Record<string, unknown> {
    if (opts.offset) {
      return {
        ...opts,
        offset: this.AMap.Pixel(...opts.offset as [])
      }
    } else {
      return opts
    }
  }
}

export default OverlayRender
