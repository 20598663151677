import get from 'lodash/get'
import filter from 'lodash/filter'
import map from 'lodash/map'
import noop from 'lodash/noop'
import merge from 'lodash/merge'
import QUMap from './quMap'

export interface Events {
  onOpen: () => void;
  onClose: () => void;
  onNext: () => void;
  onReset: () => void;
  onFinish: (overlays: unknown) => void;
  onDestroy: () => void;
}

export interface Props {
  quMap: QUMap;
  events: Partial<Events>;
}

export default class OverlayEditor {
  AMap: any
  amap: any
  mouseTool: any
  overlays: Array<any>
  isEditing: boolean
  editor: any
  events: Events

  protected constructor ({
    quMap,
    events = {}
  }: Props) {
    this.AMap = quMap.AMap
    this.amap = quMap.amap
    this.mouseTool = new this.AMap.MouseTool(this.amap)
    this.overlays = []
    this.isEditing = false
    this.events = merge({}, {
      onOpen: noop,
      onClose: noop,
      onNext: noop,
      onReset: noop,
      onFinish: noop,
      onDestroy: noop
    }, events)
  }

  /**
   * @description
   * 为了禁用双击完成事件使用的 MouseTool 内部属性，不在 amap docs 中
   */
  disableDoubleClick (): void {
    let handlers = get(this.mouseTool, 'eventsList')
    handlers = filter(
      handlers,
      (e: { type: string }) => e.type === 'dblclick'
    )
    handlers = map(handlers, (e: { fun: any }) => e.fun)
    handlers.forEach((handler: any) => {
      this.amap.off('dblclick', handler, this.mouseTool)
    })
  }

  close (clear = false): void {
    if (this.editor) {
      this.editor.setTarget()
      this.editor.close()
    }
    this.isEditing = false
    /**
     * @description HACK
     * 为了下次可以正常绘制，未结束绘制也清空 MouseTool 内部属性，可能是 amap 的 bug
     */
    this.mouseTool.polygonTemp = null
    this.mouseTool.polylineTemp = null
    this.mouseTool.close(clear)
    if (clear) {
      this.amap.remove(this.overlays)
      this.overlays = []
    }
    this.amap.setDefaultCursor('default')
    this.events.onClose()
  }

  next (): void {
    this.close()
    this.open()
    this.events.onNext()
  }

  open (): void {
    noop()
  }

  reset (): void {
    this.close(true)
    this.open()
    this.events.onReset()
  }

  finish (): void {
    this.getOverlayGroup().setOptions({
      cursor: 'default',
      draggable: false
    })
    this.close(false)
    this.events.onFinish(this.getOverlaysGeoJSON())
  }

  getOverlayGroup () {
    const overlayGroup = new this.AMap.GeoJSON({})
    overlayGroup.addOverlays(this.overlays)
    return overlayGroup
  }

  getOverlaysGeoJSON () {
    if (!this.overlays.length) {
      return
    }
    const overlayGroup = this.getOverlayGroup()
    return overlayGroup.toGeoJSON()
  }

  destroy (): void {
    this.mouseTool.close(true)
    this.amap.remove(this.overlays)
    this.mouseTool = null
    this.overlays = []
    this.events.onDestroy()
    this.amap.setDefaultCursor('default')
  }
}

// TODO:
// add type
