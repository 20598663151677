import React from 'react'
import { useQUMapValue } from '../quMap/QUMapContext'

export interface Props {
  /** 组件名称 */
  name?: string;
  /** 点击事件 */
  onClick?: () => void
}

/**
 * @description 地图缩放组件
 * @category Component
 */
const ZoomControl: React.FC<Props> = ({
  onClick
}) => {
  const quMap = useQUMapValue()

  const handleZoomInClick = () => {
    quMap.zoomIn()
  }
  const handleZoomOutClick = () => {
    quMap.zoomOut()
  }

  return (
    <div className="qu-controls__block" onClick={onClick}>
      <div className="qu-controls__item" onClick={handleZoomInClick}>+</div>
      <div className="qu-controls__item" onClick={handleZoomOutClick}>-</div>
    </div>
  )
}

ZoomControl.defaultProps = {
  name: 'ZoomControl'
}

export default ZoomControl
