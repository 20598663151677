import React, { useState, useEffect } from 'react'
import map from 'lodash/map'
import cx from 'clsx'
import Checkbox from '../ui/Checkbox'

export type Layer = {
  id: string | number,
  label: string,
  active?: boolean,
  disabled?: boolean
}

export interface Props {
  /** 组件名称 */
  name?: string;
  /** 标题 */
  title: string;
  /** 自定义 icon */
  icon?: React.ReactNode | React.ComponentType
  /** 是否激活状态 */
  active?: boolean;
  /** 点击事件 */
  onClick?: (active: boolean, name: string) => void;
  /** 图层 */
  layers?: Array<Layer>;
  /** 图层变化事件 */
  onChange?: (layers: Array<Layer>) => void;
}

/**
 * @description 测距组件
 * @category Component
 */
const LabelControl: React.FC<Props> = ({
  title = '开关',
  name,
  layers = [],
  active,
  onChange,
  onClick,
  icon
}) => {
  const [innerActive, setActive] = useState(active)

  useEffect(() => {
    setActive(active)
  }, [active])

  const handleClick = () => {
    onClick && onClick(!innerActive, name as string)
    setActive(!innerActive)
  }
  const handleChange = (id: Layer['id'], layerActive: Layer['active']) => {
    const updatedLayers = map(layers, l => {
      if (l.id === id) {
        return {
          ...l,
          active: layerActive
        }
      }
      return l
    })
    onChange && onChange(updatedLayers)
  }
  return (
    <div className="qu-controls__block">
      <div className={cx('qu-controls__item qu-controls__label', { active: innerActive })} onClick={handleClick}>
        {
          icon
            ? icon
            : <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              {/* eslint-disable-next-line max-len */}
              <path d="M13.86 0.795789C13.5189 0.312632 12.9505 0 12.3158 0L1.89474 0.00947368C0.852632 0.00947368 0 0.852632 0 1.89474V11.3684C0 12.4105 0.852632 13.2537 1.89474 13.2537L12.3158 13.2632C12.9505 13.2632 13.5189 12.9505 13.86 12.4674L18 6.63158L13.86 0.795789Z"/>
            </svg>
        }
      </div>
      {
        layers.length > 0
          ? <div className={cx('qu-controls__panel', { hide: !innerActive})}>
            <div className="qu-controls__panel-title">{title}</div>
            {
              map(layers, l => (
                <Checkbox
                  key={l.id}
                  label={l.label}
                  checked={l.active}
                  disabled={l.disabled}
                  onChange={(checked) => handleChange(l.id, checked)}
                />
              ))
            }
          </div>
          : ''
      }
    </div>
  )
}

LabelControl.defaultProps = {
  name: 'LabelControl'
}

export default LabelControl
