import React, { useState, useEffect } from 'react'
import cx from 'clsx'

export interface Props {
  /** 组件名称 */
  name: string;
  /** 是否激活状态 */
  active?: boolean;
  /** 点击事件 */
  onClick?: (active: boolean, name: string) => void;
  /** 自定义 icon */
  icon?: React.ReactNode | React.ComponentType
}

/**
 * @description 定制化控制组件
 * @category Component
 */
const CustomControl: React.FC<Props> = ({
  name,
  children,
  active,
  onClick,
  icon
}) => {

  const [innerActive, setActive] = useState(active)

  useEffect(() => {
    setActive(active)
  }, [active])

  const handleClick = () => {
    onClick && onClick(!innerActive, name)
    setActive(!innerActive)
  }
  return (
    <div className="qu-controls__block">
      <div className={cx('qu-controls__item qu-controls__label', { active: innerActive })} onClick={handleClick}>
        {icon}
      </div>
      {
        children &&
        <div className={cx('qu-controls__panel', { hide: !innerActive})}>
          {children}
        </div>
      }
    </div>
  )
}

export default CustomControl
