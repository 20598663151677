import { scaleLinear, scaleOrdinal, ScaleQuantile, scaleQuantile, ScaleQuantize, scaleQuantize } from 'd3-scale'
import isNil from 'lodash/isNil'
import { CIRCLE_MARKER_Z_INDEX, MARKER_Z_INDEX, POLYGON_Z_INDEX, TEXT_Z_INDEX } from '../constants'
import { FillConfig, LayerType, OutlineConfig, POIType, RadiusConfig } from '../types'

export const getZIndex = (poi: POIType, layer: LayerType): number => {
  const zIndex = poi.zIndex || layer.zIndex
  if (typeof zIndex !== 'undefined') {
    return zIndex
  } else {
    switch (poi.overlayType) {
      case 'Polyline':
      case 'Polygon':
        return POLYGON_Z_INDEX
      case 'Circle':
      case 'CircleMarker':
      case 'AggMarker':
        return CIRCLE_MARKER_Z_INDEX
      case 'Text':
        return TEXT_Z_INDEX
      case 'Marker':
        return MARKER_Z_INDEX
      case 'LabelMarker':
        return MARKER_Z_INDEX
      default:
        return MARKER_Z_INDEX
    }
  }
}

enum ColorScaleType {
  ordinal = 'ordinal',
  linear = 'linear',
  quantize = 'quantize',
  quantile = 'quantile'
}

type Return = ScaleQuantize<string, never> | ScaleQuantile<string, never>
export const getScaleFunction =
  (type: string, colorRange: string[], domain: number[]): Return => {
    switch (type) {
      case ColorScaleType.quantize:
        return scaleQuantize<string>().domain(domain).range(colorRange)
      case ColorScaleType.quantile:
        return scaleQuantile<string>().domain(domain).range(colorRange)
      default:
        throw Error(`Not supported colorScaleType ${type}`)
    }
  }

// TODO: improve getFillOpts perforamnce
export const getFillOpts = (poi: POIType, layer: LayerType): Partial<FillConfig> => {
  const fillOpts: Partial<FillConfig> = {
    fillOpacity: 0
  }
  if (layer.fillConfig && layer.fillConfig.enable) {
    fillOpts.fillOpacity = layer.fillConfig.fillOpacity
    if (layer.fillConfig.field) {
      const fieldValue = poi[layer.fillConfig.field]
      if (!isNil(fieldValue)) {
        const { min, max, data } = layer.fillOptions
        const scaleType = layer.fillConfig.colorScale ?? ColorScaleType.quantize
        const reversed = layer.fillConfig.colorRange.reversed
        let colors = layer.fillConfig.colorRange.colors
        if (reversed && scaleType !== ColorScaleType.ordinal) {
          colors = [...colors].reverse()
        }
        if (scaleType === ColorScaleType.ordinal) {
          const domain = layer.fillConfig.colorRange.ordinalDomain ?? data
          fillOpts.fillColor = scaleOrdinal<string>().domain(domain).range(colors)(fieldValue)
        } else {
          const domain = scaleType === ColorScaleType.quantize ? [min, max] : data
          const scaleFunction = getScaleFunction(scaleType, colors, domain)
          fillOpts.fillColor = scaleFunction(parseFloat(fieldValue))
        }
      } else {
        fillOpts.fillColor = undefined
      }
    } else {
      fillOpts.fillColor = layer.fillConfig.colorRange.colors[0]
    }
  }

  return fillOpts
}

export const getOutlineOpts = (poi: POIType, layer: LayerType): Partial<OutlineConfig> => {
  if (layer.outlineConfig && layer.outlineConfig.enable) {
    if (poi.overlayType === 'Polyline' || poi.overlayType === 'Polylines') {
      const fillOpts = getFillOpts(poi, layer)
      return {
        isOutline: true,
        outlineColor: layer.outlineConfig.strokeColor,
        borderWeight: layer.outlineConfig.strokeWeight,
        strokeWeight: 2,
        strokeOpacity: fillOpts.fillOpacity,
        strokeColor: fillOpts.fillColor
      }
    }
    return {
      strokeColor: layer.outlineConfig.strokeColor,
      strokeWeight: layer.outlineConfig.strokeWeight,
      strokeOpacity: layer.outlineConfig.strokeOpacity,
      strokeStyle: layer.outlineConfig.strokeStyle,
      strokeDasharray: layer.outlineConfig.strokeDasharray
    }
  }

  return {
    strokeWeight: 0
  }
}
export const mapNumericRange =
  (x: number, inputStart: number, inputEnd: number, outputStart: number, outputEnd: number): number => {
    const mappingFunction = scaleLinear().domain([inputStart, inputEnd]).range([outputStart, outputEnd])
    return mappingFunction(x)
  }

export const getRadiusOpts = (poi: POIType, layer: LayerType): Partial<RadiusConfig> => {
  if (layer.radiusConfig && !layer.radiusConfig.isFixed && layer.radiusConfig.field) {
    if (!isNaN(poi[layer.radiusConfig.field])) {
      const fieldValue = parseFloat(poi[layer.radiusConfig.field])
      if (layer.radiusOptions) {
        const { min, max, start, end } = layer.radiusOptions
        return {
          radius: Math.ceil(mapNumericRange(fieldValue, min, max, start, end))
        }
      } else {
        return {}
      }
    } else {
      return {}
    }
  }

  return {}
}

export const getTimestamp = (timestamp: number | string): number | string => {
  return timestamp || Date.now()
}
