export const EDIT_OVERLAY_Z_INDEX = 999
export const POLYGON_Z_INDEX = 100
export const CIRCLE_MARKER_Z_INDEX = 120
export const TEXT_Z_INDEX = 200
export const MARKER_Z_INDEX = 300
export const CONNECTED_POLYLINE_Z_INDEX = 400

export const CIRCLE_MARKER_RADIUS = 2
export const FILL_COLOR = '#2a72eb'
export const HIGHLIGHT_FILL_COLOR = '#2a72eb'
export const STROKE_COLOR = '#2a72eb'
export const HIGHLIGHT_STROKE_COLOR = '#fff'
export const FILL_OPACITY = 0.6
export const STROKE_OPACITY = 1
export const STROKE_WEIGHT = 1
export const STROKE_STYLE: 'solid' | 'dashed' | undefined = 'solid'

export const LABEL_MARKER_STYLES = {
  direction: 'center',
  offset: [0, 0],
  style: {
    fontSize: 12,
    fillColor: '#000'
  }
}

export const POLYGON_STYLES = {
  fillColor: FILL_COLOR,
  fillOpacity: FILL_OPACITY,
  strokeColor: STROKE_COLOR,
  strokeOpacity: STROKE_OPACITY,
  strokeWeight: STROKE_WEIGHT,
  strokeStyle: STROKE_STYLE
}

export const CIRCLE_MARKER_STYLES = {
  fillColor: '#F7CB47',
  strokeColor: '#fff',
  strokeWeight: 0,
  fillOpacity: 1
}

export const CONNECTED_POLYLINE_STYLES = {
  strokeWeight: 1,
  strokeColor: '#2a72eb',
  strokeOpacity: 1,
  isOutline: true,
  borderWeight: 0.5,
  outlineColor: '#ffffff'
}

export const OVERLAY_TYPES = [
  'Polygon',
  'CircleMarker',
  'Text',
  'AggMarker',
  'Polyline',
  'Marker',
  'Circle',
  'LabelMarker'
]

export const TEXT_OPTS = {
  anchor: 'top-center',
  style: {
    color: '#636363',
    'font-size': '11px',
    'font-weight': 500,
    'border-radius': '2px',
    background: 'rgba(255, 255, 255, 0.95)',
    border: 'none',
    padding: '5px'
  }
}

export const MARKER_OPTS = {
  anchor: 'bottom-center'
}
