import { CIRCLE_MARKER_STYLES, EDIT_OVERLAY_Z_INDEX } from './constants'
import * as AMapTypes from './types'
import OverlayEditor, { Props as OverlayEditorProps } from './overlayEditor'

const MAX_POINT_COUNT = 1000

interface Props extends OverlayEditorProps {
  overlayOptions?: AMap.MarkerOptions
}

class MarkerEditor extends OverlayEditor {
  static DEFAULT_OPTS = {
    ...CIRCLE_MARKER_STYLES,
    zIndex: EDIT_OVERLAY_Z_INDEX
  }

  opts: AMap.MarkerOptions

  constructor (props: Props) {
    const { overlayOptions = {}, quMap, events } = props
    super({ quMap, events })

    this.mouseTool.on('draw', (e: AMapTypes.DrawEvent) => {
      e.obj.on('dragend', () => {
        this.events.onFinish(this.getOverlaysGeoJSON())
      })
      if (this.overlays.length >= MAX_POINT_COUNT) {
        this.amap.remove(this.overlays)
        this.overlays = [e.obj]
      } else {
        this.overlays.push(e.obj)
      }

      this.events.onFinish(this.getOverlaysGeoJSON())
    })

    this.opts = {
      ...MarkerEditor.DEFAULT_OPTS,
      ...overlayOptions
    }
  }

  load (coordinates: [number, number] | [number, number][]): void {
    this.isEditing = true
    const isMultiPoints = Array.isArray(coordinates[0])
    const overlay = isMultiPoints ?
      (coordinates as [number, number][]).map(c => {
        return new this.AMap.Marker({
          draggable: true,
          position: c,
          ...this.opts
        })
      })
      : new this.AMap.Marker({
        draggable: true,
        position: coordinates,
        ...this.opts
      })
    this.overlays = this.overlays.concat(overlay)
    this.amap.add(this.overlays)
  }

  open (): void {
    this.isEditing = true
    this.amap.setDefaultCursor('crosshair')
    this.mouseTool.marker({
      draggable: true,
      ...this.opts
    })
    this.events.onOpen()
  }
}

export default MarkerEditor
