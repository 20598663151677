/* eslint-disable */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { QUMap as QUMapClass, QUMapTypes } from '@quanturban/map-core'
import { QUMapContext } from './QUMapContext'
import { MemoQUMap } from './MemoQUMap'

type noop = (event: any) => void;
const quMapGroupLayerDefaultName = performance.now()

export interface Props {
  token?: string;
  version?: string;
  options?: Partial<QUMapTypes.IMapConfig>;
  plugins?: string[];
  style?: React.CSSProperties;
  className?: string;
  quMapRef?: any;
  // Callbacks
  onLoad?: (quMap: QUMapClass) => void;
  onResize?: noop;
  onHover?: noop;
  onClick?: noop;
  onDragStart?: noop;
  onDrag?: noop;
  onDragEnd?: noop;
  onZoomEnd?: noop;
  onError?: noop;
}

/**
 * 地图支持的plugins:           https://lbs.amap.com/api/javascript-api/reference/plugin
 * AMap.MouseTool:             鼠标工具插件
 * AMap.CircleEditor:          圆编辑插件
 * AMap.RectangleEditor:       矩形编辑插件
 * AMap.PolyEditor:            折线、多边形编辑插件
 * AMap.GeoJSON:               GeoJSON用于实现GeoJSON和OverlayGroup的相互转化
 * AMap.PlaceSearch:           地点搜索服务插件
 */

/**
 * @description 地图组件
 * @param token         地图API Key
 * @param version       地图API版本, 默认为2.0
 * @param options       地图选项: mapStyle, zoom, center, rotation
 * @param children      地图容器内的子组件: layer, controller, poi等
 * @param plugins       地图支持的plugins: AMap.MouseTool, AMap.CircleEditor, AMap.RectangleEditor, AMap.PolyEditor,
 *   AMap.GeoJSON, AMap.PlaceSearch
 * @param style         为地图容器添加样式
 * @param className     为地图容器添加className
 * @param onLoad        地图加载完成后触发该事件
 * @param onResize      地图size变化后触发该事件
 * @param onHover       当鼠标进入地图时触发该事件
 * @param onClick       当鼠标点击地图时触发该事件
 * @param onDragStart   开始拖动地图时触发该事件
 * @param onDrag        拖动地图时触发该事件
 * @param onDragEnd     拖动地图结束时触发该事件
 * @param onZoomEnd     zoom变化结束时触发该事件
 * @param onError       地图发生错误时触发该事件
 */
const QUMap: React.FC<Props> = (props: Props) => {
  const {
    token,
    children,
    quMapRef,
    options,
    onError
  } = props
  const [amap, setAmap] = useState<QUMapClass>()
  const [quMap, setQUMap] = useState<QUMapClass>()

  useEffect(() => {
    if (amap) {
      amap.setMapStyle(`amap://styles/${options.mapStyle}`)
    }
  }, [options.mapStyle])

  const handleQUMapInitialized = (instance, map) => {
    setQUMap(instance)
    setAmap(map)
    if (quMapRef) {
      quMapRef.current = instance
    }
  }

  const renderProvider = () => {
    if (quMap && amap) {
      return (
        <QUMapContext.Provider value={quMap}>
          {
            React.Children.map(children, (c, idx) => {
              if (React.isValidElement(c)) {
                if (c.type.displayName === 'GroupLayer' && !c.props.name) {
                  return React.cloneElement(c, {
                    name: `${quMapGroupLayerDefaultName}_${c.key}`,
                    ...c.props
                  })
                } else {
                  return React.cloneElement(c, c.props)
                }
              }
            })
          }
        </QUMapContext.Provider>
      )
    }
  }

  const renderQUMap = () => {
    if (token) {
      return (
        <div className={props.className ? `qu-map ${props.className}` : 'qu-map'} style={props.style}>
          <MemoQUMap {...props} onMapInitialized={handleQUMapInitialized} />
          {renderProvider()}
        </div>
      )
    } else {
      onError && onError('请传入API Key!')
      return <div>请传入高德地图API Key!</div>
    }
  }

  return renderQUMap()
}

export default QUMap
