import { EDIT_OVERLAY_Z_INDEX, POLYGON_STYLES } from './constants'
import * as AMapTypes from './types'
import OverlayEditor, { Props as OverlayEditorProps } from './overlayEditor'

interface Props extends OverlayEditorProps {
  overlayOptions?: AMap.PolylineOptions;
  /** polylineEditor 设置参数 https://lbs.amap.com/api/jsapi-v2/documentation#polylineeditor */
  editorOptions?: any;
}

const MAX_POLYLINE_COUNT = 1000

class PolylineEditor extends OverlayEditor {
  static DEFAULT_OPTS = {
    ...POLYGON_STYLES,
    zIndex: EDIT_OVERLAY_Z_INDEX
  }

  opts: AMap.PolylineOptions

  constructor (props: Props) {
    const { overlayOptions = {}, quMap, events } = props
    super({ quMap, events })


    this.editor = new this.AMap.PolylineEditor(this.amap)
    this.mouseTool.on('draw', (e: AMapTypes.DrawEvent) => {
      const polyline = e.obj
      const pointsCount = polyline.getPath().length

      if (pointsCount > 1) {
        this.overlays.push(polyline)
        polyline.setOptions({ cursor: 'pointer' })

        polyline.on('click', () => {
          if (this.isEditing) {
            this.editor.setTarget(polyline)
            this.editor.open()
            // 临时关闭 mouseTool 防止绘制新点
            this.mouseTool.close()
            setTimeout(() => {
              this.mouseTool.polyline({
                draggable: false,
                ...this.opts
              })
            }, 0)
          }
        })
        this.events.onFinish(this.getOverlaysGeoJSON())
      } else {
        // 删除错误的 Polyline，让用户重新绘制
        this.amap.remove(e.obj)
      }

      if (this.overlays.length >= MAX_POLYLINE_COUNT) {
        this.amap.setDefaultCursor('default')
        this.mouseTool.close()
      }

    })
    this.opts = {
      ...PolylineEditor.DEFAULT_OPTS,
      strokeWeight: 5,
      ...overlayOptions
    }
  }

  load (path: AMapTypes.PolylineOptions['path']): void {
    this.close()
    const overlay = new this.AMap.Polyline({
      path,
      ...this.opts
    })
    this.overlays.push(overlay)
    this.amap.add(this.overlays)
    this.editor.setTarget(overlay)
    this.editor.open()
    this.isEditing = true
  }

  open (): void {
    this.isEditing = true
    this.amap.setDefaultCursor('crosshair')
    this.mouseTool.polyline({
      draggable: false,
      ...this.opts
    })

    this.disableDoubleClick()
    this.events.onOpen()
  }
}

export default PolylineEditor

// TODO:
// 1. 验证 load 参数中 path 是否符合格式
