import React from 'react'

type overlayType = 'IconMarker'
  | 'CircleMarker'
  | 'LabelMarker'
  | 'Polyline'
  | 'Polygon'
  | 'Circle'
  | 'Text'
  | 'AggMarker'
  | 'Marker'

export interface Props {
  /**
   * 需要绑定事件的POI类型,
   * 如果没有该参数, 则事件会绑定到所在GroupLayer内所有类型的POI上.
   */
  type?: overlayType;
  /** 鼠标点击POI事件处理函数 */
  onClick?: (event?: any) => void;
  /** 鼠标移入POI事件处理函数 */
  onHover?: (event?: any) => void;
  /** 开始拖动POI事件处理函数 */
  onDragStart?: (event?: any) => void;
  /** 拖动POI过程事件处理函数 */
  onDrag?: (event?: any) => void;
  /** 拖动POI结束事件处理函数 */
  onDragEnd?: (event?: any) => void;
}

/**
 * POI事件
 */
const Event: React.FC<Props> = () => {
  return <></>
}

Event.displayName = 'Event'

export default Event
