import pick from 'lodash/pick'
import get from 'lodash/get'
import map from 'lodash/map'
import forEach from 'lodash/forEach'
import find from 'lodash/find'
import filter from 'lodash/filter'
import every from 'lodash/every'
import partition from 'lodash/partition'
import xor from 'lodash/xor'
import includes from 'lodash/includes'
import intersection from 'lodash/intersection'
import keys from 'lodash/keys'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import { getTimestamp } from './utils/overlayFns'
import {
  AMapGroup,
  AMapLayer,
  ConfigType,
  DataDiffType,
  DataSetType,
  DataSourceType,
  DataType,
  GroupLayersType,
  GroupLayerType,
  LayerDiffType,
  LayerType,
  MixOverlay,
  NumberRangeType,
  OverlayOptions,
  QUMapProps
} from './types'
import OverlayRender from './overlayRender'
import { clearOverlaysEvents, initOverlaysEvents } from './events'

class Overlay {
  AMap: any
  amap: AMap.Map
  group: AMapGroup[]
  iconLabelsLayerZIndex: number
  groupLayers: GroupLayersType = {}
  overlayRender: OverlayRender
  eventsWrapper: any
  eventsHandlers: any

  constructor (props: QUMapProps) {
    this.AMap = props.AMap
    this.amap = props.amap
    // FixMe: 开启避让检测后, 同一个点无法显示多个label
    this.setLabelLayerCollision(true)
    this.group = []
    this.overlayRender = new OverlayRender(props)
  }

  saveStyles (overlay: MixOverlay): void {
    overlay.setExtData({
      ...overlay.getExtData(),
      amapOriginalStyles: pick(overlay.getOptions(), [
        'zIndex',
        'fillColor',
        'fillOpacity',
        'strokeColor',
        'strokeOpacity',
        'strokeWeight',
        'strokeStyle'
      ])
    })
  }

  toggleSelect (
    overlay: OverlayOptions,
    opts: {
      zIndex?: number
    }
  ): void {
    const extData = overlay.getExtData()
    const { amapOriginalStyles, select } = extData

    if (select) {
      overlay.setOptions(amapOriginalStyles)
    } else {
      if (!amapOriginalStyles) {
        this.saveStyles(overlay)
      }
      overlay.setOptions({
        ...opts,
        zIndex: opts.zIndex || overlay.getOptions().zIndex + 2
      })
    }

    overlay.setExtData({
      ...overlay.getExtData(),
      select: !select
    })
  }

  select (
    overlay: OverlayOptions,
    opts: {
      zIndex?: number
    }
  ): void {
    const extData = overlay.getExtData()
    const { amapOriginalStyles } = extData

    if (!amapOriginalStyles) {
      this.saveStyles(overlay)
    }

    overlay.setOptions({
      ...opts,
      zIndex: opts.zIndex || overlay.getOptions().zIndex + 2
    })
    overlay.setExtData({
      ...overlay.getExtData(),
      select: true
    })
  }

  toggleActive (overlay: OverlayOptions, opts = {}): void {
    const extData = overlay.getExtData()
    const { amapOriginalStyles, active, select } = extData

    if (select) {
      return
    }

    if (active) {
      overlay.setOptions(amapOriginalStyles)
    } else {
      if (!amapOriginalStyles) {
        this.saveStyles(overlay)
      }
      overlay.setOptions(opts)
    }

    overlay.setExtData({
      ...overlay.getExtData(),
      active: !active
    })
  }

  toggleActiveLabelMarker (overlay: OverlayOptions): void {
    const extData = overlay.getExtData()
    const { active, highlightColor } = extData

    if (active) {
      overlay._opts.text.style = {
        ...overlay._opts.text.style,
        fillColor: 'black',
        backgroundColor: 'white'
      }
      overlay.setTop(false)
    } else {
      overlay._opts.text.style = {
        ...overlay._opts.text.style,
        fillColor: 'white',
        backgroundColor: highlightColor || 'black'
      }
      overlay.setTop(true)
    }

    overlay.setExtData({
      ...overlay.getExtData(),
      active: !active
    })
  }

  enableHighlight (
    overlay: OverlayOptions,
    opts: OverlayOptions
  ): void {
    overlay.on('mouseover', () => {
      const extData = overlay.getExtData()
      if (extData.highlight || extData.active || extData.select) {
        return
      }

      this.saveStyles(overlay)
      overlay.setOptions(opts)
      overlay.setExtData({
        ...overlay.getExtData(),
        highlight: true
      })
    })

    overlay.on('mouseout', () => {
      const extData = overlay.getExtData()
      if (!extData.highlight || extData.active || extData.select) {
        return
      }

      if (extData.amapOriginalStyles) {
        overlay.setOptions(extData.amapOriginalStyles)
        overlay.setExtData({
          ...overlay.getExtData(),
          highlight: false
        })
      }
    })
  }

  getOverlayByProperty (prop: string, value: unknown): OverlayOptions[] {
    return filter(this.amap.getAllOverlays(), (overlay) => {
      const extData = overlay.getExtData()
      return extData[prop] === value
    })
  }

  getOverlayByProperties (props: {
    [key: string]: unknown
  }): OverlayOptions[] {
    return filter(this.amap.getAllOverlays(), (overlay) => {
      const extData = overlay.getExtData()
      return every(props, (val, prop) => {
        return extData[prop] === val
      })
    })
  }

  getOverlayGroupByName (name: string): AMap.OverlayGroup | undefined {
    return get(
      find(this.group, (g) => g.name === name),
      'overlayGroup'
    )
  }

  addEvents (overlay: OverlayOptions, events: AMap.Eventable): AMap.Eventable {
    return forEach(events, (event, name) => {
      overlay.on(name, event)
    })
  }

  removeEvents (overlay: OverlayOptions, events: AMap.Eventable): AMap.Eventable {
    return forEach(events, (event, name) => {
      overlay.off(name, event)
    })
  }

  clearEvents (overlay: OverlayOptions, eventNames: Array<string>): OverlayOptions {
    forEach(eventNames, (eventName) => {
      overlay.clearEvents(eventName)
    })

    return overlay
  }

  remove (overlay: AMap.OverlayGroup | AMap.OverlayGroup[]): void {
    this.amap && this.amap.remove(overlay as any)
  }

  createGroup (name: string, overlays: MixOverlay[], options = {}, groupLayerName: string): AMap.OverlayGroup {
    const overlayGroup = new this.AMap.OverlayGroup(overlays as any)

    this.group.push({
      name,
      options,
      overlayGroup,
      groupLayerName,
      type: get(overlays, '[0].type')
    })

    return overlayGroup
  }

  removeGroupByName (name: string): void {
    this.group = filter(this.group, (g) => g.name !== name)
  }

  removeGroups (names: string[]): void {
    const [groupToRemove, group] = partition(this.group, (g) =>
      includes(names, g.name)
    )
    this.remove(groupToRemove.map((g) => g.overlayGroup))
    this.group = group
  }

  removeAllGroups (): void {
    forEach(this.group, (g) => {
      this.remove(g.overlayGroup)
    })
  }

  setLabelLayerCollision (collision = false): void {
    const overlay = new this.AMap.LabelMarker({
      position: [0, 0],
      visible: false,
      extData: {}
    })
    this.amap.add(overlay)
    map(this.amap.getLayers(), (layer: AMapLayer) => {
      if (layer.CLASS_NAME === 'AMap.LabelsLayer') {
        if (get(layer, '_opts.isIconLabelMarker')) {
          if (this.iconLabelsLayerZIndex !== layer.zIndex) {
            layer.setzIndex(this.iconLabelsLayerZIndex || layer.zIndex)
          }
        } else {
          layer.setCollision(collision)
          this.iconLabelsLayerZIndex &&
          (this.iconLabelsLayerZIndex = layer.zIndex - 1)
        }
      }
    })

    overlay.remove()
  }

  createOverlays (poiIds: string[], layer: LayerType, dataSet: DataSetType): MixOverlay[] {
    const overlays: MixOverlay[] = []
    map(poiIds, poiId => {
      const poi = dataSet.pois[poiId]
      const type = poi.overlayType
      const layerType = layer.layerType

      if (layerType === 'IconMarker' && !includes(['Polyline', 'Polygon'], type)) {
        overlays.push(this.overlayRender.createIconMarker(poi, layer))
      } else if (layerType === 'LabelMarker') {
        overlays.push(this.overlayRender.createLabelMarker(poi, layer))
      } else if (layerType === 'DefaultCircleMarker' && !includes(['Polyline', 'Polygon'], type)) {
        overlays.push(this.overlayRender.createCircleMarker(poi, layer))
      } else if (layerType === 'OtherMarker') {
        switch (type) {
          case 'Polyline':
          case 'Polylines':
            overlays.push(this.overlayRender.createPolyline(poi, layer))
            break
          case 'Polygon':
          case 'Polygons':
            overlays.push(this.overlayRender.createPolygon(poi, layer))
            break
          case 'Circle':
            overlays.push(this.overlayRender.createCircle(poi, layer))
            break
          case 'Text':
            overlays.push(this.overlayRender.createText(poi, layer))
            break
          case 'AggMarker':
            overlays.push(this.overlayRender.createAggMarker(poi, layer))
            break
          case 'Marker':
            overlays.push(this.overlayRender.createMarker(poi, layer))
            break
          case 'CircleMarker':
            overlays.push(this.overlayRender.createCircleMarker(poi, layer))
            break
          case 'Points': {
            const pois = poi.coordinates.map(coordinates => ({ ...poi, coordinates }))
            overlays.push(...pois.map(poi => this.overlayRender.createCircleMarker(poi, layer)))
            break
          }
        }
      }
    })
    return overlays
  }

  getDataSetId (dataId: string): string {
    return `dataSet_${dataId}`
  }

  getRangeByField (field: string, groupLayerName: string, layer: LayerType): NumberRangeType {
    const dataSet = this.groupLayers[groupLayerName].dataSet
    const currDataIds = dataSet[this.getDataSetId(layer.dataId)]
    let min = Number.MAX_SAFE_INTEGER, max = 0
    const data = map(currDataIds, id => {
      const data = dataSet.pois[id]
      if (!isNaN(data[field])) {
        const value = parseFloat(data[field])
        max = max < value ? value : max
        min = min > value ? value : min
      }
      if (!isNil(data[field])) {
        return data[field]
      }
    })

    return {
      min,
      max,
      data
    }
  }

  // 计算config.layers的差值
  diffLayers (config: ConfigType, groupLayerName: string): void {
    const currentLayers = this.groupLayers[groupLayerName].layers
    const newLayers: {
      [key: string]: any
    } = {}
    map(config.layers, layer => {
      if (layer.isVisible) {
        const timestamp = getTimestamp(layer.timestamp)
        if (layer.iconConfig && layer.iconConfig.enable) {
          // Add icon layer if there is iconConfig
          newLayers[`${layer.id}_icon_${timestamp}`] = {
            ...layer,
            layerType: 'IconMarker'
          }
        } else {
          if (layer.showCircleMarker) {
            // Add CircleMarker as default if there is no iconConfig provided
            newLayers[`${layer.id}_circleMarker_${timestamp}`] = {
              ...layer,
              layerType: 'DefaultCircleMarker'
            }
          }
        }
        // OtherMarker as default if there is no iconConfig provided
        newLayers[`${layer.id}_otherMarker_${timestamp}`] = {
          ...layer,
          layerType: 'OtherMarker'
        }

        // Add labels
        map(layer.labels, label => {
          if (typeof label.visible === 'undefined' || label.visible) {
            newLayers[`${layer.id}_custom_label_${label.field}_${timestamp}`] = {
              ...layer,
              label,
              layerType: 'LabelMarker'
            }
          }
        })
      }
    })

    const layersDiff: LayerDiffType = {}
    const newLayersIds = keys(newLayers)
    const prevLayersIds = keys(currentLayers)
    const intersectionLayersIds = intersection(prevLayersIds, newLayersIds)
    layersDiff.addLayerIds = xor(newLayersIds, intersectionLayersIds)
    layersDiff.removeLayerIds = xor(prevLayersIds, intersectionLayersIds)

    /**
     * 如果是`填充, 描边, 半径`等属性相关layer:
     * 如果fillConfig.field!==null, 需计算fillConfig.field相关参数
     * min=对应fillConfig.field的所有数据的最小值,
     * max=对应fillConfig.field的所有数据的最大值,
     * ---------------------------------------------
     * 如果radiusConfig.field!==null, 并且isFixed=false, 需计算radiusConfig.field相关参数
     * min=对应radiusConfig.field的所有数据的最小值,
     * max=对应radiusConfig.field的所有数据的最大值,
     */
    for (let i = 0; i < newLayersIds.length; i++) {
      const currLayer = newLayers[newLayersIds[i]]
      if (currLayer.layerType === 'OtherMarker' ||
        currLayer.layerType === 'DefaultCircleMarker' ||
        currLayer.layerType === 'LabelMarker') {
        if (currLayer.fillConfig && currLayer.fillConfig.enable && currLayer.fillConfig.field) {
          const fillRange = this.getRangeByField(currLayer.fillConfig.field, groupLayerName, currLayer)
          currLayer.fillOptions = {
            min: fillRange.min,
            max: fillRange.max,
            data: fillRange.data
          }
        }
        if (currLayer.radiusConfig && !currLayer.radiusConfig.isFixed && currLayer.radiusConfig.field) {
          const radiusRange = this.getRangeByField(currLayer.radiusConfig.field, groupLayerName, currLayer)
          currLayer.radiusOptions = {
            min: radiusRange.min,
            max: radiusRange.max,
            start: currLayer.radiusConfig.radiusRange[0],
            end: currLayer.radiusConfig.radiusRange[1]
          }
        }
      }
    }

    this.groupLayers[groupLayerName].layers = newLayers
    this.groupLayers[groupLayerName].layerDiff = layersDiff
  }

  // 计算dataSet差值
  diffGroupLayerData (dataSource: DataType, groupLayerName: string): void {
    const currentGroupLayer = this.getCurrentGroupLayer(groupLayerName)

    const newDataSet = {
      pois: {}
    }
    map(dataSource, data => {
      const dataSetId = this.getDataSetId(data.id)
      newDataSet[dataSetId] = []
      map(data.data, poi => {
        const formattedId = [data.id, poi.id, getTimestamp(poi.timestamp)].join('__')
        poi.extData = {
          ...poi.extData,
          qu_formattedId: formattedId,
          qu_groupLayerName: groupLayerName
        }
        newDataSet[dataSetId].push(formattedId)
        newDataSet.pois[formattedId] = poi
      })
    })

    const dataDiff: DataDiffType = {}
    const prevPoiIds = keys(currentGroupLayer.dataSet.pois)
    const newPoiIds = keys(newDataSet.pois)
    const intersectionPoiIds = intersection(prevPoiIds, newPoiIds)
    dataDiff.addPoiIds = xor(newPoiIds, intersectionPoiIds)
    dataDiff.removePoiIds = xor(prevPoiIds, intersectionPoiIds)
    currentGroupLayer.dataSet = cloneDeep(newDataSet)
    currentGroupLayer.dataDiff = dataDiff
  }

  removeGroupLayer (name: string): void {
    if (this.groupLayers[name]) {
      delete this.groupLayers[name]
    }

    const removeGroupNames: string[] = []
    map(this.group, (g: AMapGroup) => {
      if (g.groupLayerName === name) {
        removeGroupNames.push(g.name)
      }
    })

    this.removeGroups(removeGroupNames)
  }

  getCurrentGroupLayer (name: string): GroupLayerType {
    if (!this.groupLayers[name]) {
      this.groupLayers[name] = {
        dataSet: {},
        dataDiff: {},
        layers: [],
        layerDiff: {}
      }
    }

    return this.groupLayers[name]
  }

  /**
   * 格式化GroupLayer传入的data参数
   */
  formatGroupLayerData (dataSource: DataSourceType, groupLayerName: string): void {
    // 计算原始数据的差值
    this.diffGroupLayerData(dataSource.data, groupLayerName)
    // 计算图层layers的差值
    this.diffLayers(dataSource.config, groupLayerName)
  }

  renderGroupLayer (dataSource: DataSourceType, groupLayerName: string): void {
    this.formatGroupLayerData(dataSource, groupLayerName)
    const { addPoiIds, removePoiIds } = this.groupLayers[groupLayerName].dataDiff
    const dataSet = this.groupLayers[groupLayerName].dataSet
    const { addLayerIds, removeLayerIds } = this.groupLayers[groupLayerName].layerDiff
    const layers = this.groupLayers[groupLayerName].layers

    let overlays
    let group
    if (removePoiIds && removePoiIds.length) {
      this.overlayRender.removeOverlaysByIds(removePoiIds)
    }
    if (removeLayerIds && removeLayerIds.length) {
      map(removeLayerIds, layerId => {
        group = this.getOverlayGroupByName(layerId)
        if (group) {
          group.removeOverlays(group.getOverlays())
        }

        this.removeGroups([layerId])
      })
    }

    // Update all POIs in addLayers
    if (addLayerIds && addLayerIds.length) {
      map(addLayerIds, layerId => {
        group = this.getOverlayGroupByName(layerId)
        const layer = layers[layerId]
        // Rerender all POIs in this layer
        const layerPoiIds: string[] = dataSet[this.getDataSetId(layer.dataId)]
        if (layerPoiIds && layerPoiIds.length) {
          overlays = this.createOverlays(layerPoiIds, layer, dataSet)
          const overlayGroups: AMap.OverlayGroup = this.createGroup(layerId, overlays, {}, groupLayerName)
          if (!isEmpty(overlayGroups)) {
            this.amap.add(overlayGroups as any)
          }
        }
      })
    } else {
      if (addPoiIds && addPoiIds.length) {
        map(keys(layers), layerId => {
          const layer = layers[layerId]
          const layerPoiIds: string[] = []
          addPoiIds.map(addPoiId => {
            if (!addPoiId.indexOf(`${layer.dataId}__`)) {
              layerPoiIds.push(addPoiId)
            }

            return addPoiId
          })
          overlays = this.createOverlays(layerPoiIds, layer, dataSet)
          const overlayGroups: AMap.OverlayGroup = this.createGroup(layerId, overlays, {}, groupLayerName)
          if (!isEmpty(overlayGroups)) {
            this.amap.add(overlayGroups as any)
          }

          if (!this.groupLayers[groupLayerName].dataSet[this.getDataSetId(layer.dataId)].length) {
            this.removeGroups([layerId])
          }
        })
      }
    }
  }

  poiEventsWrapper (event: any): void {
    const extData = get(event, `target`).getExtData()
    if (extData.qu_eventTypes) {
      extData.qu_eventTypes.map((eventType: string) => {
        const rawEventType: string = event.type === 'mouseover' ? 'hover' : event.type === 'dragging' ? 'drag' : event.type
        if (`on${rawEventType}` === eventType.toLowerCase()) {
          let handler = get(this.eventsHandlers, `[${extData.qu_groupLayerName}_${eventType}__${extData.qu_type}].handler`)
          if (!handler) {
            // 处理没有传入type的event
            handler = get(this.eventsHandlers, `[${extData.qu_groupLayerName}_${eventType}].handler`)
          }
          handler && handler({
            event,
            poi: get(this.groupLayers, `['${extData.qu_groupLayerName}'].dataSet.pois['${extData.qu_formattedId}']`)
          })
        }
      })
    }
  }

  initEventsHandlers (events: any) {
    this.eventsHandlers = {
      ...this.eventsHandlers,
      ...events
    }
  }

  initOverlaysEvents (name: string, events: any) {
    if (!this.eventsWrapper) {
      this.eventsWrapper = this.poiEventsWrapper.bind(this)
    }

    this.initEventsHandlers(events)
    initOverlaysEvents(this.amap.getAllOverlays(), events, this.eventsWrapper)
  }

  clearOverlaysEvents (events: any): void {
    events && clearOverlaysEvents(this.amap.getAllOverlays(), events, this.eventsWrapper)
  }

  destroy (): void {
    this.amap.remove(this.amap.getAllOverlays())
    this.removeAllGroups()
    this.amap.destroy()
    this.group = []
  }
}

export default Overlay

// TODO:
// 3. Label collision check
