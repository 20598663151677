/* eslint-disable */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import getEventValue from './utils/getEventValue'
import '@amap/amap-jsapi-types'
import { MixOverlay } from './types'
import { QUMap } from './index'

export interface EventProps {
  onLoad?: (info: any, event: any) => void;
  onResize?: (info: any, event: any) => void;
  onHover?: (info: any, event: any) => void;
  onClick?: (info: any, event: any) => void;
  onDragStart?: (info: any, event: any) => void;
  onDrag?: (info: any, event: any) => void;
  onDragEnd?: (info: any, event: any) => void;
  onZoomEnd?: (info: any, event: any) => void;
}

type EventTarget = AMap.Map

const getEventType = (eventType: string): string => {
  switch (eventType) {
    case 'onLoad':
      return 'load'
    case 'onResize':
      return 'resize'
    case 'onHover':
      return 'mouseover'
    case 'onClick':
      return 'click'
    case 'onDragStart':
      return 'dragstart'
    case 'onDrag':
      return 'dragging'
    case 'onDragEnd':
      return 'dragend'
    case 'onZoomEnd':
      return 'zoomend'
    default:
      return eventType
  }
}

export function initEvents (target: EventTarget, props: EventProps, AMap: AMap.NameSpace, quMap: QUMap) {
  for (const prop in props) {
    if (props.hasOwnProperty(prop) && prop.indexOf('on') === 0) {
      const eventType: string = getEventType(prop)
      if (eventType === 'load') {
        target.on('complete', (ev: AMap.Eventable) => {
          if (props[prop]) {
            // onLoad 的info里面返回的是quMap实例, 包含amap和AMap
            // amap: 高德地图实例
            // AMap: 高德地图全局API
            // quMap: 完整的quMap实例
            props[prop](getEventValue(ev, {
              amap: target,
              AMap,
              quMap
            }))
          }
        })
      } else {
        target.on(eventType, (ev: AMap.Eventable) => {
          if (props[prop]) {
            props[prop](getEventValue(ev, target))
          }
        })
      }
    }
  }
}

export function clearEvents (target: EventTarget) {
  // TODO: tobe continue
}

function isDraggableEventType (eventType) {
  return ['dragstart', 'dragging', 'dragend'].indexOf(eventType) > -1
}

function isDraggableOverlay (overlay) {
  return !!overlay.setDraggable
}

function handleOverlaysEvents (overlays: MixOverlay[], events: any, wrapperHandler, isClear: boolean) {
  overlays.map(overlay => {
    Object.keys(events).map(eventName => {
      const currEvent = events[eventName]
      const eventType: string = getEventType(currEvent.type)
      const groupLayerName: string = currEvent.groupLayerName
      const extData = overlay.getExtData()
      if ((extData.qu_type === currEvent.poiType || typeof currEvent.poiType === 'undefined') && groupLayerName === extData.qu_groupLayerName) {
        overlay.off(eventType, wrapperHandler)
        let eventTypes = extData.qu_eventTypes || []
        if (isClear) {
          eventTypes.splice(eventTypes.indexOf(currEvent.type), 1)
        } else {
          if (isDraggableEventType(eventType) && !isDraggableOverlay(overlay)) {
            return
          }
          overlay.on(eventType, wrapperHandler)
          if (eventTypes.indexOf(currEvent.type) < 0) {
            eventTypes.push(currEvent.type)
          }
        }

        overlay.setExtData({
          ...extData,
          qu_eventTypes: eventTypes
        })

        if (isDraggableEventType(eventType) && isDraggableOverlay(overlay)) {
          overlay.setDraggable(!isClear)
        }
      }
    })

    return overlay
  })
}

export function clearOverlaysEvents (overlays: MixOverlay[], events: any, wrapperHandler) {
  handleOverlaysEvents(overlays, events, wrapperHandler, true)
}

export function initOverlaysEvents (overlays: MixOverlay[], events, wrapperHandler) {
  handleOverlaysEvents(overlays, events, wrapperHandler, false)
}

// TODO:
// 1. Add type
