import React, { useEffect, useRef, useState } from 'react'
import cx from 'clsx'
import merge from 'lodash/merge'
import { Distance } from '@quanturban/map-core'
import { useQUMapValue } from '../quMap/QUMapContext'

export interface Props {
  /** 组件名称 */
  name?: string;
  /** 是否激活状态 */
  active?: boolean;
  /** 点击事件 */
  onClick?: (active: boolean, name?: string) => void;
}

function renderDistanceResult (result) {
  return (
    <div className="qu-controls__distance">
      <div className="qu-controls__panel-section">
        {
          result.ruler &&
          <div className="qu-controls__distance-item">
            <span className="qu-controls__distance-label">直线距离</span>
            <span>{result.ruler.distance} 公里</span>
          </div>
        }
      </div>
      <div className="qu-controls__panel-section">
        <div className="qu-controls__panel-title">交通距离</div>
        {result.driving && renderDistanceResultItem(result.driving, '驾车')}
        {result.transfer && renderDistanceResultItem(result.transfer, '公交')}
        {result.walking && renderDistanceResultItem(result.walking, '步行')}
      </div>
    </div>
  )
}

function renderDistanceResultItem (item, label) {
  return (
    <div className="qu-controls__distance-item">
      <span className="qu-controls__distance-label">{label}</span>
      {`约${item.time}分钟`}
      <span className="qu-controls__distance-divider" />
      {`${item.distance}公里`}
    </div>
  )
}

/**
 * @description 测距组件
 * @category Component
 */
const DistanceControl: React.FC<Props> = ({
  name,
  active,
  onClick
}) => {
  const quMap = useQUMapValue()
  const [innerActive, setActive] = useState(active)
  const [result, setResult] = useState<any>(null)
  const distance = useRef<Distance>()

  useEffect(() => {
    async function init () {
      await quMap.addPlugins(['AMap.MouseTool', 'AMap.Driving', 'AMap.Walking', 'AMap.Transfer'])

      distance.current = new Distance(quMap)
    }

    init()

    return () => {
      distance.current?.close()
    }
  }, [quMap])

  useEffect(() => {
    if (!innerActive && distance.current) {
      distance.current.close()
      setResult(null)
    }
  }, [innerActive, quMap])

  useEffect(() => {
    setActive(active)
  }, [active])

  const handleClick = () => {
    distance.current?.open((type, res) => {
      setResult(prevState => {
        return merge({}, prevState, {
          [type]: res
        })
      })
    })

    onClick && onClick(!innerActive, name)
    setActive(!innerActive)
  }

  return (
    <div className="qu-controls__block">
      <div className={cx('qu-controls__item qu-controls__label', { active: innerActive })} onClick={handleClick}>
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          {/* eslint-disable-next-line max-len */}
          <path
            fillRule="evenodd" clipRule="evenodd"
            d="M1.63636 4.5H16.3636C17.2636 4.5 18 5.23636 18 6.13636V12.6818C18 13.5818 17.2636 14.3182 16.3636 14.3182H1.63636C0.736364 14.3182 0 13.5818 0 12.6818V6.13636C0 5.23636 0.736364 4.5 1.63636 4.5ZM1.63636 12.6818H16.3636V6.13636H14.7273V9.40909H13.0909V6.13636H11.4545V9.40909H9.81818V6.13636H8.18182V9.40909H6.54545V6.13636H4.90909V9.40909H3.27273V6.13636H1.63636V12.6818Z" />
        </svg>
      </div>
      <div className={cx('qu-controls__panel', { hide: !innerActive })}>
        <div className="qu-controls__panel-title">测距工具</div>
        {
          result
            ? renderDistanceResult(result)
            : <div className="qu-controls__panel-text">请点击地图测距</div>
        }
      </div>
    </div>
  )
}

DistanceControl.defaultProps = {
  name: 'DistanceControl'
}

export default DistanceControl

// TODO:
// 1. extract quMap.AMap.plugin
