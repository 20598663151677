import React, { useEffect, useState } from 'react'
import map from 'lodash/map'
import filter from 'lodash/filter'
import uniq from 'lodash/uniq'
import cx from 'clsx'

import { useQUMapValue } from '../quMap/QUMapContext'
import RadioGroup from '../ui/RadioGroup'
import Checkbox from '../ui/Checkbox'

export interface RoadLayerStatsType {
  show: string[];
  hide: string[];
}

export interface Props {
  /** 组件名称 */
  name?: string;
  /** 底图变化事件 */
  onChange?: (layer: string) => void;
  /** 是否激活状态 */
  active?: boolean;
  /** 点击事件 */
  onClick?: (active: boolean, name?: string) => void;
  /** 默认使用底图 */
  defaultTileLayer?: string;
  /** 底图 */
  tileLayer?: string;
}

type Layer = {
  value: string,
  label: string
}

const presetTileLayers: Layer[] = [
  {
    label: '谷歌卫星图（纯净版）',
    value: 'GooglePureSatellite'
  },
  {
    label: '谷歌卫星图',
    value: 'GoogleSatellite'
  },
  {
    label: '高德卫星图',
    value: 'AmapSatellite'
  },
  {
    label: '高德浅色地图',
    value: 'AmapLight'
  },
  {
    label: '高德深色地图',
    value: 'AmapDark'
  },
  {
    label: '高德标准图',
    value: 'AmapStandard'
  }
]

const presetRoadLayers: Layer[] = [
  {
    label: '路网图',
    value: 'roadNet'
  },
  {
    label: '路况图',
    value: 'traffic'
  }
]

/**
 * @description 底图切换组件
 * @category Component
 */
const TileLayerControl: React.FC<Props> = ({
  name,
  defaultTileLayer,
  tileLayer,
  active,
  onClick,
  onChange
}) => {
  const [innerActive, setActive] = useState<undefined | boolean>(active)
  const [roadLayerStats, setRoadLayerStats] = useState<RoadLayerStatsType>({
    show: [],
    hide: []
  })
  const quMap = useQUMapValue()

  useEffect(() => {
    setActive(active)
  }, [active])

  useEffect(() => {
    quMap.useRoadLayers(roadLayerStats.show, roadLayerStats.hide)
  }, [roadLayerStats, quMap])

  const handleClick = () => {
    onClick && onClick(!innerActive, name)
    setActive(!innerActive)
  }

  const handleTileLayerChange = (value: string) => {
    quMap.changeTileLayer(value)
    onChange?.(value)
  }

  const handleRoadLayerChange = (value: string, checked: boolean) => {
    if (checked) {
      setRoadLayerStats(prev => {
        return {
          ...prev,
          show: uniq([...prev.show, value]),
          hide: filter(prev.hide, l => l !== value)
        }
      })
    } else {
      setRoadLayerStats(prev => {
        return {
          ...prev,
          show: filter(prev.show, l => l !== value),
          hide: uniq([...prev.hide, value])
        }
      })
    }
  }

  return (
    <div className="qu-controls__block">
      <div className={cx('qu-controls__item qu-controls__label', { active: innerActive })} onClick={handleClick}>
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          {/* eslint-disable-next-line max-len */}
          <path
            fillRule="evenodd" clipRule="evenodd"
            d="M7.99111 14.7024L1.44 9.60907L0 10.7291L8 16.9513L16 10.7291L14.5511 9.60018L7.99111 14.7024ZM8 12.4444L14.5422 7.35111L16 6.22222L8 0L0 6.22222L1.44889 7.35111L8 12.4444Z" />
        </svg>
      </div>
      <div className={cx('qu-controls__panel', { hide: !innerActive })}>
        <div className="qu-controls__panel-section">
          <RadioGroup
            name="tileLayer"
            options={presetTileLayers}
            defaultValue={defaultTileLayer}
            value={tileLayer}
            onChange={handleTileLayerChange}
          />
        </div>
        <div className="qu-controls__panel-section">
          {
            map(presetRoadLayers, l => (
              <Checkbox
                key={l.value}
                label={l.label}
                onChange={(checked) => handleRoadLayerChange(l.value, checked)}
              />
            ))
          }
        </div>
      </div>
    </div>
  )
}

TileLayerControl.defaultProps = {
  name: 'TileLayerControl'
}

export default TileLayerControl

// TODO:
// 1. extract presetTileLayers and presetRoadLayers
