/* eslint-disable */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import forEach from 'lodash/forEach'
import * as AMapLoader from '@amap/amap-jsapi-loader'
import { initEvents } from './events'
import * as QUMapTypes from './types'
import type { AMap } from './types'
import Overlay from './overlay'
import { QUMAP_DEFAULT_OPTIONS, QUMAP_DEFAULT_VERSION } from './constants/quMapConfig'

export default class QUMap {
  AMap: any
  amap: AMap.Map
  layer: any
  roadLayers: {
    [key: string]: any
  }
  overlay: Overlay
  promise: Promise<AMap.Map>
  mapSearch: any
  props: QUMapTypes.AMapOptions

  constructor (props: QUMapTypes.AMapOptions) {
    this.layer = null
    this.roadLayers = {}
    this.props = props
    const { key, version = QUMAP_DEFAULT_VERSION, plugins = [], id, options } = props
    if (window.AMap) {
      AMapLoader.load({
        key,
        version,
        plugins
      })
      this.initMapInstance(window.AMap, id, options)
      this.promise = Promise.resolve(this.amap)
    } else {
      this.promise = new Promise((resolve, reject) => {
        AMapLoader.load({
          key,
          version,
          plugins
        }).then(AMap => {
          this.initMapInstance(AMap, id, options)
          resolve(this.amap)
        }).catch((err) => {
          reject(err)
        })
      })
    }

    return this
  }

  initMapInstance (AMap, id, options) {
    this.AMap = AMap
    this.amap = new this.AMap.Map(id, {
      ...QUMAP_DEFAULT_OPTIONS,
      ...options,
      mapStyle: options.mapStyle ? `amap://styles/${options.mapStyle}` : QUMAP_DEFAULT_OPTIONS.mapStyle
    } as AMap.MapOptions)
    this.initOverlay()
    this.initSearch()
    this.intiMapEvents()
  }

  renderGroupLayer (data, name) {
    this.overlay.renderGroupLayer(data, name)
  }

  initOverlaysEvents (name, events) {
    this.overlay.initOverlaysEvents(name, events)
  }

  removeGroupLayer (name) {
    this.overlay.removeGroupLayer(name)
  }

  clearOverlaysEvents (events) {
    this.overlay.clearOverlaysEvents(events)
  }

  intiMapEvents () {
    initEvents(this.amap, this.props, this.AMap, this)
  }

  getAMap () {
    return this.AMap
  }

  initOverlay () {
    this.overlay = new Overlay({
      AMap: this.AMap,
      amap: this.amap
    })
  }

  initSearch () {
    if (this.AMap.PlaceSearch) {
      this.mapSearch = new this.AMap.PlaceSearch({
        pageSize: 20
      })
    }
  }

  search (name: string, city: string) {
    this.mapSearch.setCity(city)
    this.mapSearch.setCityLimit(true)

    return new Promise((resolve, reject) => {
      this.mapSearch.search(
        name,
        (
          status: string,
          result: {
            poiList: {
              pois: [
                {
                  geometry: {
                    coordinates: number[]
                    type: string
                  }
                  location: {
                    lng: number
                    lat: number
                  }
                }
              ]
            }
          }
        ) => {
          if (status === 'error') {
            reject(status)
          } else {
            if (status === 'no_data') {
              resolve({
                results: []
              })
            } else {
              forEach(result.poiList.pois, (p) => {
                p.geometry = {
                  coordinates: [p.location.lng, p.location.lat],
                  type: 'Point'
                }
              })
              resolve({
                results: result.poiList.pois
              })
            }
          }
        }
      )
    })
  }

  addLayer (layer: any) {
    this.getMap().then((map) => {
      map.add(layer)
    })
  }

  useGoogleSatelliteLayer (opts: AMap.TileLayerOptions) {
    this.getMap().then((map) => {
      const googleLayer = new this.AMap.TileLayer({
        getTileUrl:
          'http://mt{1,2,3,0}.google.cn/vt/lyrs=s@142&hl=zh-CN&gl=cn&x=[x]&y=[y]&z=[z]&s=Galil',
        zIndex: 2,
        dataZooms: [2, 20],
        ...opts
      } as AMap.TileLayerOptions)

      if (this.layer) {
        map.removeLayer(this.layer)
      }
      map.addLayer(googleLayer)
      this.layer = googleLayer
    })
  }

  useDefaultLayer () {
    this.getMap().then((map) => {
      if (this.layer) {
        map.removeLayer(this.layer)
      }

      map.setMapStyle('amap://styles/normal')
    })
  }

  useSatelliteLayer (opts: AMap.SatelliteLayerOptions) {
    this.getMap().then((map) => {
      const layer = new this.AMap.TileLayer.Satellite({
        ...opts
      })

      if (this.layer) {
        map.removeLayer(this.layer)
      }
      map.addLayer(layer)
      this.layer = layer
    })
  }

  useWhiteStyleLayer () {
    this.getMap().then((map) => {
      if (this.layer) {
        map.removeLayer(this.layer)
      }

      map.setMapStyle('amap://styles/whitesmoke')
    })
  }

  useGreyStyleLayer () {
    this.getMap().then((map) => {
      if (this.layer) {
        map.removeLayer(this.layer)
      }

      map.setMapStyle('amap://styles/grey')
    })
  }

  useRoadLayers (layerNames: Array<string>, layerNamesToHide: Array<string>) {
    this.getMap().then((map) => {
      forEach(layerNamesToHide, (name) => {
        const prevLayer = this.roadLayers[name]
        if (prevLayer) {
          map.removeLayer(prevLayer)
        }
      })

      forEach(layerNames, (name) => {
        let layer: any

        if (name === 'roadNet') {
          layer = new this.AMap.TileLayer.RoadNet()
        }

        if (name === 'traffic') {
          layer = new this.AMap.TileLayer.Traffic()
        }

        this.amap.addLayer(layer)
        this.roadLayers[name] = layer
      })
    })
  }

  changeTileLayer (layerName) {
    if (layerName === 'GooglePureSatellite') {
      this.useGoogleSatelliteLayer()
      this.amap.setFeatures([])
      return
    }
    this.amap.setFeatures(['bg', 'road', 'building', 'point'])
    if (layerName === 'GoogleSatellite') {
      this.useGoogleSatelliteLayer()
    }
    if (layerName === 'AmapSatellite') {
      this.useSatelliteLayer()
    }
    if (layerName === 'AmapStandard') {
      this.useDefaultLayer()
    }
    if (layerName === 'AmapLight') {
      this.useWhiteStyleLayer()
    }
    if (layerName === 'AmapDark') {
      this.useGreyStyleLayer()
    }
  }

  destroy () {
    this.overlay && this.overlay.destroy()
    this.amap && this.amap.remove(this.amap.getLayers())
    this.layer = null
    this.roadLayers = {}
    this.AMap = null
    this.amap.destroy()
  }

  getMap (): Promise<AMap.Map> {
    return this.promise
  }

  zoomIn () {
    this.amap.zoomIn()
  }

  zoomOut () {
    this.amap.zoomOut()
  }

  addPlugins (plugins) {
    return new Promise((resolve, reject) => {
      this.AMap.plugin(plugins, (res) => {
        resolve(res)
      })
    })
  }
}
