import React, { useState } from 'react'
import cx from 'clsx'

type Position = 'br' | 'bl' | 'tr' | 'tl';

export interface Props {
  /** 控制组件展示位置 */
  position?: Position;
  /** 默认激活的控制组件 */
  defaultActiveControl?: string;
  className?: string;
}

/**
 * @description 地图控制器组件
 * @category Component
 */
const Controls: React.FC<Props> = ({
  position = 'br',
  children,
  defaultActiveControl,
  className
}) => {
  const [activeControl, setControl] = useState<undefined | string>(defaultActiveControl)
  const handleClick = (active: boolean, name?: string) => {
    if (active && name) {
      setControl(name)
    }
  }
  return <div className={cx('qu-controls', `qu-controls--${position}`, className)}>
    {
      React.Children.map(children, c => {
        return React.isValidElement(c) && React.cloneElement(c, {
          active: c.props.name === activeControl,
          onClick: handleClick
        })
      })
    }
  </div>
}

export default Controls

// TODO: 不同 position 子组件不同的样式
