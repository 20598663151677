import React, { ChangeEvent, useState } from 'react'
import cx from 'clsx'

export interface Props {
  checked?: boolean;
  label: string;
  onChange?: (checked: boolean) => void;
  defaultChecked?: boolean;
  disabled?: boolean;
}


/**
 * @description 多选组件
 * @internal
 * @category Component
 */
const Checkbox: React.FC<Props> = ({
  checked = false,
  label,
  onChange,
  disabled = false,
  defaultChecked
}) => {
  const [status, setStatus] = useState(checked)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setStatus(!status)
    onChange && onChange(e.target.checked)
  }

  return (
    <label className={cx('qu-checkbox', { checked: status })}>
      <input
        checked={status}
        type="checkbox"
        disabled={disabled}
        onChange={handleChange}
      />
      <span className="qu-checkbox__label">{label}</span>
    </label>
  )
}

export default Checkbox

// TODO:
// 1. controlled component
// 2. style
