import '@amap/amap-jsapi-types'
import { RulerEnd } from './types'
import QUMap from './quMap'

const MAX_POINT_COUNT = 2

export interface ResultType {
  distance: number;
  time?: number;
}

export type Callback = (type: string, res?: ResultType) => void

class Distance {
  AMap: any
  amap: any
  ruler: any
  driving: any
  walking: any
  transfer: any
  markerGroup: any
  callback: Callback

  constructor (quMap: QUMap) {
    this.AMap = quMap.AMap
    this.amap = quMap.amap
    this.ruler = new this.AMap.RangingTool(this.amap)
    this.driving = new this.AMap.Driving()
    this.walking = new this.AMap.Walking()
    this.transfer = new this.AMap.Transfer()
    this.ruler.on('addnode', (event: { marker: AMap.Marker }) => {
      this.markerGroup.push(event.marker)
      if (this.markerGroup.length === MAX_POINT_COUNT) {
        this.ruler.turnOff()
      }
    })
    this.ruler.on('removenode', () => {
      this.open()
    })

    this.ruler.on(['delete', 'removenode'], () => {
      this.open()
    })

    this.ruler.on('end', this.handleRulerEnd.bind(this))
  }

  open (callback?: Callback): void {
    this.markerGroup = []
    this.ruler.turnOn()
    if (callback) {
      this.callback = callback
    }
    this.amap.getCity((result: { city: string }) => {
      result.city && this.transfer.setCity(result.city)
    })
  }

  close (): void {
    this.ruler.turnOff(true)
    this.ruler.off('end', this.handleRulerEnd.bind(this))
  }

  handleRulerEnd (rulerResult: RulerEnd): void {
    this.callback('ruler', Distance.formatResult(rulerResult))
    const startPoint = rulerResult.points[0]
    const endPoint = rulerResult.points[rulerResult.points.length - 1]
    this.transfer.search(
      startPoint,
      endPoint,
      (
        status: string,
        result: {
          plans?: ResultType[]
        }
      ) => {
        if (status === 'complete' && result.plans) {
          this.callback('transfer', Distance.formatResult(result.plans[0]))
        } else {
          this.callback('transfer')
        }
      }
    )
    this.walking.search(
      startPoint,
      endPoint,
      (
        status: string,
        result: {
          routes?: ResultType[]
        }
      ) => {
        if (status === 'complete' && result.routes) {
          this.callback('walking', Distance.formatResult(result.routes[0]))
        } else {
          this.callback('walking')
        }
      }
    )
    this.driving.search(
      startPoint,
      endPoint,
      (
        status: string,
        result: {
          routes?: ResultType[]
        }
      ) => {
        if (status === 'complete' && result.routes) {
          this.callback('driving', Distance.formatResult(result.routes[0]))
        } else {
          this.callback('driving')
        }
      }
    )
  }

  private static transformToKm (m): string {
    return m ? (m / 1000).toFixed(2) : ''
  }

  private static transformToMin (s): string {
    return s ? (s / 60).toFixed(0) : ''
  }

  private static formatResult (result: ResultType): ResultType {
    return {
      distance: parseInt(Distance.transformToKm(result.distance)),
      time: parseInt(Distance.transformToMin(result.time))
    }
  }

  destroy (): void {
    this.ruler = null
    this.driving = null
    this.walking = null
    this.transfer = null
  }
}

export default Distance
