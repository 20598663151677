export default function getEventValue (ev: any, component: any) {
  const type = ev.type
  switch (type) {
    case 'complete':
      return {
        info: component,
        event: ev
      }
    case 'zoomend':
      return {
        info: {
          type,
          zoom: component.getZoom(),
          center: component.getCenter().toString()
        },
        event: ev
      }
    default:
      return {
        info: {
          type,
          // TODO: 如果不是map事件, 可能不存在getCenter
          center: component.getCenter().toString()
        },
        event: ev
      }
  }
}
