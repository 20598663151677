import React, { useEffect, useState } from 'react'
import { useQUMapValue } from '../quMap/QUMapContext'

export interface Props {
  /**
   * GroupLayer名称
   * 注意: 如果存在多个 `GroupLayer`,
   *       请为每个GroupLayer提供唯一的 `name` 属性,
   *       如果没有 `name` 属性, 那么所提供的 `key` 必须与 `data` 相关, 并且不重复.
   *       否则, 在控制不同 `GroupLayer` 的显示与否可能存在意想不到的问题. */
  name?: string;
  /** 待渲染的POI数据 */
  data?: {
    data: any[];
    config: any;
  };
  children?: any;
}

/**
 * GroupLayer 图层
 */
export const GroupLayer: React.FC<Props> = (props: Props) => {
  const quMap = useQUMapValue()
  const { name, data, children } = props
  const [events, setEvents] = useState<any>()
  const [changeEvents, setChangeEvents] = useState(0)

  useEffect(() => {
    return () => {
      quMap.removeGroupLayer(name)
    }
  }, [])

  useEffect(() => {
    quMap.renderGroupLayer(data, name)
    setChangeEvents(Date.now())
  }, [data])

  useEffect(() => {
    if (!changeEvents) {
      return
    }

    const newEvents: any = {}
    React.Children.map(children, c => {
      if (React.isValidElement(c)) {
        const type: any = c.type
        const prop: any = c.props
        if (type.displayName === 'Event') {
          for (const p in prop) {
            if (!p.indexOf('on')) {
              let handler = `${name}_${p}`
              if (prop.type) {
                handler += `__${prop.type}`
              }
              newEvents[handler] = {
                groupLayerName: name,
                type: p,
                poiType: prop.type,
                handler: prop[p]
              }
            }
          }
        }
      }
    })

    setEvents(newEvents)
    quMap.initOverlaysEvents(name, newEvents)

    return () => {
      quMap.clearOverlaysEvents(events)
    }
  }, [children, changeEvents])
  return <>{children}</>
}

GroupLayer.displayName = 'GroupLayer'
